@import "../../css/variables.scss";

.Footer {
    letter-spacing: $LetterSpaceMiddle;
    text-transform: uppercase;
    letter-spacing: $LetterSpaceCap;
    text-align: center;
    font-weight: $fontWeightLight;
    font-size: 14px;
    padding: 16px 0;
    color: $textWhite;
    background-color: $textGray;

    @include pc {
        padding: 12px 0;
    }
}