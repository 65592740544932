.top-to-btm{
    position: relative;
}
  
.icon-position{
    position: fixed;
    bottom: 40px;
    right: 5%;
    z-index: 99;

    @include pc {
        bottom: 120px;
    }
}

.icon-style{
    background-color: rgba($color: $textWhite, $alpha: 0.8);
    border-radius: 50%;
    height: 48px;
    width: 48px;
    color: $navy;
    padding: 8px;
    cursor: pointer;
}

.icon-style:hover{
    animation: none;
    background: #fff;
    color: $navy;
}

@keyframes movebtn {
    0%{
        transform: translateY(0px);
    }
    25%{
        transform: translateY(20px);
    }
    50%{
        transform: translateY(0px);
    }
    75%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0px);
    }
}