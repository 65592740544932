@import "../../css/variables.scss";

.Hero_wrapper {
    height: 100vh;
    width: 90%;
    margin: 0 auto;
    color: $navy;
    letter-spacing: $LetterSpaceMiddle;
    position: relative;

    @include pc {
        width: 1100px;
    }
}

.Hero_title_wrapper {
    padding-top: 24vh;
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;

    @include pc {
        padding-top: 20vh;
        margin-bottom: 80px;
    }
}

.Hero_title {
    font-family: $fontTitle;
    margin-bottom: 32px;

    @include pc {
        letter-spacing: $LetterSpaceCap;
    }
}

.Hero_title_name {
    font-size: 36px;
    margin-bottom: 4px;
    display: inline-block;
    letter-spacing: $LetterSpaceMiddle;

    @include pc {
        font-size: 72px;
        margin-bottom: 8px;
    }
}

.Hero_title_name_bold {
    font-weight: $fontWeightBold;
    font-size: 48px;
    margin-left: 4px;
    letter-spacing: $LetterSpaceMiddle;

    @include pc {
        font-size: 100px;
    }
}

.Hero_title_job {
    font-size: 24px;
    letter-spacing: $LetterSpaceMiddle;

    @include pc {
        font-size:64px;
    }
}

.Hero_title_job_bold {
    font-weight: $fontWeightBold;
    font-size: 36px;

    @include pc {
        font-size: 80px;
    }
}

.Hero_text {
    font-weight: $fontWeightNormal;
    letter-spacing: $LetterSpaceBase;
    line-height: $LetterHeightBase;
    margin-bottom: 8px;

    @include pc {
        font-size: 20px;
    }
}

// scroll
.Hero_scroll {
    display: flex;
    flex-direction: column;
    // align-items: center;
}

.Hero_scroll_down {
    // display: inline-block;
    width: 1.2px;
    height: 80px;
    background: $navy;
    animation: scrollDown 1.6s ease infinite;
    margin-bottom: 24px;
    // margin: 0 auto;
}

@keyframes scrollDown{

    0%{
        transform-origin: top;
        transform: scaleY(0);
    }
    45%{
        transform-origin: top;
        transform: scaleY(1);
    }
    55%{
        transform-origin: bottom;
        transform: scaleY(1);
    }
    100%{
        transform-origin: bottom;
        transform: scaleY(0);
    }
}

.Hero_scroll_text {
    font-size: 14px;
}

// animation
@keyframes fade{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
}

@keyframes ScrollDown {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 40px);
    }
}

@keyframes gradient{
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}

@keyframes scall{
    0% {
      height: 100vh;
    }
    100% {
      height: 90vh;
    }
}