@import "../../css/variables.scss";

.Maintenance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.MaintenanceTitle {
    text-align: center;
    font-weight: $fontWeightBold;
    color: $navy;
    letter-spacing: $LetterSpaceMiddle;
    font-size: 24px;
    // text-transform: uppercase;
    margin-bottom: 8px;

    @include pc {
        font-size: 2.4em;
    }
}

.MaintenanceText {
    text-align: center;
    color: $textBlack;
    letter-spacing: $LetterSpaceBase;
    font-weight: $fontWeightNormal;
    letter-spacing: $LetterSpaceBase;
    font-size: 16px;

    a {
        color: $navy;
        font-weight: $fontWeightBold;
        font-size: 1.2em;
    }
}

.MaintenanceContact {
    margin-top: 20px;

    a {
        color: $textBlack;
        font-weight: $fontWeightNormal;
        text-decoration: none;
        display: inline-block;
        margin-top: 5px;
    }
}