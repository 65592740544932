// tablet
@mixin tablet {
    @media (min-width: ($tablet)) {
        @content;
    }
}

// pc
@mixin pc {
    @media (min-width: ($pc)) {
        @content;
    }
}
