@import "../../css/variables.scss";

.Contact {
    padding: 48px 0;
    text-align: center;
    background-color: $navy;
    overflow: hidden;

    @include pc {
        padding: 36px 0;
    }
}

.Contact_title {
    font-size: 36px;
    margin-bottom: 16px;
    font-weight: $fontWeightBold;
    color: $pink;
    font-family: $fontTitle;
    letter-spacing: $LetterSpaceMiddle;

    @include pc {
        font-size: 52px;
    }
}

@keyframes MoveBigContactTitle {
    0% {
        transform:scale(1,1);
    }
    100% {
        transform:scale(1.15,1.15);
    }
}

.Contact_email {
    font-size: 24px;
    display: flex;
    justify-content: center;
    letter-spacing: $LetterSpaceMiddle;
    transition: $transition_hover;
    font-weight: $fontWeightMedium;
    color: $textWhite;

    @include pc {
        font-size: 32px;
    }
}

.Contact_email_email {
    position: relative;
    display: inline-block;
    transition: $transition_hover;
}

.Contact_email_email:hover {

    @include pc {
        color: $pink;
        transition: $transition_hover;
    }
}

.Contact_email_emoji {
    margin-right: 12px;
    font-size: 1.25em;
    animation-name: scale;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 0.7s;

    @include pc {
        margin-right: 16px;
    }
}

.Social_media {
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 210px;

    @include pc {
        width: 240px;
        margin-top: 24px;
    }
}

.Social_media_link {
    background-color: $textWhite;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: $transition_hover;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(57, 112, 184, 0.2) 0px 48px 100px 0px;

    @include pc {
        width: 52px;
        height: 52px;
    }
}

.Social_media_link:hover {

    @include pc {
        transform:scale(1.2,1.2);
        transition: $transition_hover;
        box-shadow: rgba(57, 112, 184, 0.5) 0px 48px 100px 0px;
    }
}

.Social_media_link_ico {

    @include pc {
        width:28px;
    }
}

.Social_media_link_ico {
    color: $textWhite;
}

@keyframes scale{
    0%{
        transform:scale(1, 1);
    }
    100%{
        // opacity: 0;
        transform:scale(1.5,1.5);
    }
}

