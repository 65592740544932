@import "../../css/variables.scss";

.Header_wrapper {
    background-color: $textWhite;
    width: 100%;
    padding: 16px 0;
    display: flex;
    z-index: 1;
    background-color: rgba($color: $textWhite, $alpha: 0.9);

    @include pc {
        padding: 12px 0;
    }
}

// .Home .Header_wrapper, .About .Header_wrapper {
//     position: fixed;
// }

.Header {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include pc {
        width: 1100px;
    }
}

.Header_logo {

    img {
        height: 32px;

        @include pc {
            height: 40px;
        }
    }
}

.Header_navigation {
    display: flex;
    width: 240px;
    justify-content: space-between;

    @include pc {
        width: 280px;
    }
}

.Header_navigation_item {
    color: $textGray;
    font-size: 16px;
    letter-spacing: 0.04em;
    position: relative;
    display: inline-block;
    transition: transform .3s;
    // text-transform: uppercase;
    font-weight: $fontWeightNormal;
}

.Header_navigation_item:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    content: '';
    width: 100%;
    height: 1.5px;
    background: $navy;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform .3s;
}

.Header_navigation_item:hover {

    @include pc {
        color: $textLightBlack;
        transition: transform .3s;
    } 
}

.Header_navigation_item:hover:after {

    @include pc {
        transform-origin: left top;
        transform: scale(1, 1);
    } 
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    top: 22px;
    right: 5%;
}
  
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: $navy;
    height: 1.5px !important;
}
  
  /* Color/shape of burger icon bars on hover*/
//   .bm-burger-bars-hover {
//     background: #a90000;
//   }
  
/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: $offWhite;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}