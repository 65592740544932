@import "../../css/variables.scss";

.CaseStudy_wrapper {
    animation-name: fade;
    animation-duration: 2s;
}

.CaseStudy_container {
    // width: 90%;
    // margin: 0 auto;

    @include pc {
        // width: 1050px;
        margin: 0 auto;
    }
}

.CaseStudy .Header_navigation_item__work {
    color: $navy;
}

// hero
.CaseStudy_hero {
    width: 100%;
    height: auto;
    margin: auto;
    margin-bottom: 60px;

    @include pc {
        text-align: center;
        margin-bottom: 120px; 
    }

    &__MonaCo {
        background-color: #F8F1EB;
    }

    &__VeganMenu {
        background-color: #91A88E;
    }
}

.CaseStudy_hero_img {
    width: 100%;

    @include pc {
        width: 1280px;
    }
}

// section 
.CaseStudy_section {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;

    @include pc {
        width: 1050px;
        margin-bottom: 180px;
        display: flex;
        justify-content: space-between;
    }

    &__maxWidth {
        width: 100%;

        @include pc {
            width: 1280px;
        }
    }
}

.CaseStudy_section_row {

    margin-bottom: 24px;

    @include pc {
        display: flex;
        justify-content: space-between;
    }

    &__styleGuide {

        @include pc {
            margin-bottom: 64px;
        }
    }
}

.CaseStudy_section_title {
    
    @include pc {
        width: 40%;
    }
}

.CaseStudy_section_title_title {
    font-weight: $fontWeightBold;
    letter-spacing: $LetterSpaceBase;
    font-size: 28px;
    margin-bottom: 32px;
    padding-left: 16px;
    border-left: 4px solid #CA9C7C;
}

.CaseStudy_section_title_sub {
    font-weight: $fontWeightBold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $textGray;
    font-size: 16px;
    margin-bottom: 4px;
    display: inline-block;
}

.CaseStudy_section_content {

    @include pc {
        width: 685px;
    }
}

.CaseStudy_section_content_item {
    margin-bottom: 48px;

    @include pc {
        margin-bottom: 64px;
    }
}

.CaseStudy_section_content_item:last-child {
    margin-bottom: 0px;

    @include pc {
        margin-bottom: 0px;
    }
}

.CaseStudy_section_content_item_title {
    font-weight: $fontWeightBold;
    letter-spacing: $LetterSpaceBase;
    font-size: 24px;
    margin-bottom: 16px;
    color: $textBlack;
}

.CaseStudy_section_content_item_text {
    letter-spacing: $LetterSpaceBase;
    font-size: 16px;
    line-height: $LetterHeightBig;
    margin-bottom: 16px;
    color: $textLightBlack;

    @include pc {
        font-size: 18px;
    }

    .bold {
        font-weight: $fontWeightBold;
    }
}

// about
.CaseStudy_about_wrapper {
    // padding-bottom: 60px;
    // border-bottom: 1px solid #7998A5;
    margin: 0 auto;
    margin-bottom: 60px;

    @include pc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 180px;
    }
}

.CaseStudy_about_row {
    margin-bottom: 48px;

    @include pc {
        width: 60%;
    }
}

.CaseStudy_about_title_wrapper {
    margin-bottom: 24px;
}

.CaseStudy_about_title_title {
    font-weight: $fontWeightBold;
    // text-transform: uppercase;
    letter-spacing: $LetterSpaceMiddle;
    font-size: 28px;
    margin-bottom: 4px;

    @include pc {
        font-size: 36px;
    }
    // &__chataid {
    //     color: #C8A3B5;
    // }
}

.CaseStudy_about_title_text {
    letter-spacing: $LetterSpaceBase;
    font-size: 18px;
    margin-bottom: 4px;

    @include pc {
        font-size: 20px;
    }
}

.CaseStudy_about_text {
    letter-spacing: $LetterSpaceBase;
    font-size: 16px;
    line-height: $LetterHeightBig;
    color: $textLightBlack;

    @include pc {
        font-size: 18px;
    }

    .bold {
        font-weight: $fontWeightBold;
    }
}

// about_type
.CaseStudy_about_type {
    @include pc {
        width: 25%;
    }
}

.CaseStudy_about_type_item {
    margin-bottom: 12px;
    display: flex;

    @include pc {
        flex-direction: column;
        margin-bottom: 24px;
    }
}

.CaseStudy_about_type_item_title {
    font-weight: $fontWeightBold;
    letter-spacing: $LetterSpaceMiddle;
    margin-right: 8px;
    font-size: 16px;
    // text-transform: uppercase;
    color: $textGray;

    @include pc {
        margin-bottom: 4px;
    }
}

.CaseStudy_about_type_item_text {
    font-size: 16px;
    font-weight: $fontWeightNormal;
    letter-spacing: $LetterSpaceBase;

    @include pc {
        font-size: 17px;
        line-height: 1.5em;
    }
}

.CaseStudy_section_content_item_img {
    width: 100%;
    height: auto;

    @include pc {
        width: 1040px;
    }
    
    &__moodboard {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    // &__wireframe {
    //     @include pc {
    //         width: 1280px;
    //     }
    // }
}

// Mood Board
.CaseStudy_moodBoard_wrapper {
    display: flex;
    flex-direction: column;
}

// Style Guide
.CaseStudy_styleGuide_wrapper {
    display: flex;
    flex-direction: column;
}

// Wireframe
.CaseStudy_wireframe_wrapper {
    width: 100%;
    margin: 0 auto;
    background-color: #eaeaea;
    text-align: center;
    margin-bottom: 100px;

    @include pc {
        margin-bottom: 180px;
        // height: 1262.25px;
    }
}

// Final Design
.CaseStudy_finalDesign_wrapper {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 100px;

    @include pc {
        margin-bottom: 180px;
    }

    &__monaCo {
        background-color: #EED8CC;
    }
}

.CaseStudy_section_content_item_img__finalDesign__veganMenu {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 90%;

    @include pc {
        width: 1050px;
    }
}




