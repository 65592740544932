@import "../../css/variables.scss";

.DailyUI_wrapper {
    animation-name: fade;
    animation-duration: 2s;
}

// hero
.DailyUI_hero {
    // background: linear-gradient(90deg, rgba(247, 154, 47, 1), rgb(251, 210, 232));
    background-image: url(../../imgs/dailyUI/dailyUI_hero.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    margin-bottom: 40px;

    @include pc {
        margin-bottom: 60px;
    }
}

.DailyUI_hero_text {
    font-weight: $fontWeightBold;
    color: $textWhite;
    text-align: center;
    padding: 80px 0;
    letter-spacing: $LetterSpaceBase;
    font-size: 32px;
    background-color: rgba(65, 65, 65, 0.4);
    filter: drop-shadow(0px 0px 2px rgba(65, 65, 65,0.25));

    @include pc {
        font-size: 48px;
        padding: 140px 0;
    }

}

// intro
.DailyUI_intro {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;

    @include pc {
        margin-bottom: 60px;
    }
}

.DailyUI_intro_text {
    letter-spacing: $LetterSpaceBase;
    font-size: 16px;
    line-height: 1.5em;

    @include pc {
        text-align: center;
        font-size: 20px;
    }
}

// list
.DailyUI_list_wrapper {
    background-color: #fafafa;
    padding: 80px 0;
}

.DailyUI_list {
    width: 90%;
    margin: auto;
    // margin-bottom: 80px;

    @include pc {
        width: 1100px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

// item
.DailyUI_list_item {
    margin-bottom: 32px;

    @include pc {
        width: 500px;
        margin-bottom: 64px;
    }
}

.DailyUI_list_item_img {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: $transition_hover;

    @include pc {
        margin-bottom: 20px;
    }
}

.DailyUI_list_item_img:hover {

    @include pc {
        transition: $transition_hover;
        transform:scale(0.95,0.95);
        box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;

    }
}

.DailyUI_list_item_text {
    text-align: center;
    font-weight: $fontWeightBold;
    font-size: 1em;
    letter-spacing: $LetterSpaceBase;

    @include pc {
        font-size: 1.2em;
    }
}

// bottom text
.DailyUI_text_bottom {
    text-align: center;
    font-weight: $fontWeightBold;
    color: $textGray;
    letter-spacing: $LetterSpaceBase;
    font-size: 1.3em;
}
