@import "../../css/variables.scss";

.About_wrapper {
    animation-name: fade;
    animation-duration: 2s;
}

.About_title_wrapper {
    background: $navy;
    background-size: 100% 100%;
    margin: auto;
    margin-bottom: 40px;

    @include pc {
        margin-bottom: 72px;
    }
}

.About_title {
    text-transform: uppercase;
    color: $pink;
    font-family: $fontTitle;
    text-align: center;
    font-weight: $fontWeightBold;
    letter-spacing: $LetterSpaceCap;
    letter-spacing: 0.07em;
    font-size: 32px;
    background-size: 100% 100%;
    margin: auto;
    padding: 40px 0;

    @include pc {
        font-size: 44px;
        padding: 100px 0;
    }
}

.About_content_wrapper {
    margin: 0 auto;
    width: 90%;
    letter-spacing: $LetterSpaceBase;
    margin-bottom: 80px;

    @include pc {
        width: 1100px;
        margin-bottom: 130px;
    }
}

.About_content_row {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;

    @include pc {
        flex-direction: row;
        justify-content: space-between;
    }
}

.About_content:first-child {

    @include pc {
        width: 600px;
    }
}

// text
.About_text_wrapper {
    margin-bottom: 16px;
}

.About_text {
    line-height: $LetterHeightBig;
    margin-bottom: 24px;
    color: $textLightBlack;
}

.About_text:last-child {
    margin-bottom: 16px;
}

.About_text_bold {
    font-weight: $fontWeightBold;
    color: $textBlack;
}

// link
.About_link_wrapper {
    display: flex;
    margin-bottom: 80px;
}

.About_link {
    font-size: 18px;
    letter-spacing: $LetterSpaceMiddle;
    text-decoration: underline;
    text-underline-offset: 0.32em;
    transition: $transition_hover;
    font-weight: $fontWeightMedium;
    margin-right: 24px;
    color: $blue;

    @include pc {
        margin-right: 32px;
    }
}

.About_link:hover {

    @include pc {
        color: $navy;
        transition: $transition_hover;
    } 
}

.About_interest_title {
    font-size: 24px;
    margin-bottom: 8px;
    display: block;
    letter-spacing: $LetterSpaceBase;
    font-weight: $fontWeightBold;
    color: $textBlack;
}

.About_interest_text {
    font-size: 16px;
    line-height: 1.7em;
    letter-spacing: $LetterSpaceBase;
    // color: $textGray;
    font-weight: $fontWeightNormal;

    @include pc {
        font-size: 18px;
    }
}

// skills
.About_skills {
    // padding: 80px 0;
    margin-bottom: 100px;

    // @include pc {
    //     padding: 140px 0;
    // }
}

.About_skills_title {
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    font-size: 32px;
    margin-bottom: 40px;
    letter-spacing: $LetterSpaceCap;
    color: $navy;
    font-family: $fontTitle;
    align-items: center;
    display: flex;
    justify-content: center;

    @include pc {
        font-size: 36px;
        margin-bottom: 48px;
    }
}

.About_skills_title::after {
    background-color: $navy;
    content: "";
    height: 0.8px;
    width: 100%;
}

.About_skills_title::after {
    margin-left: 16px;
}

.About_skills_list {

    @include pc {
        display: flex;
        justify-content: space-between;
    }
}

.About_skills_item {
    margin-bottom: 32px;

    @include pc {
        margin-bottom: 0;
        width: 31%;
    }
}

.About_skills_item:last-child {
    margin-bottom: 0;
}

.About_skills_item_title {
    font-size: 20px;
    margin-bottom: 8px;
    letter-spacing: $LetterSpaceMiddle;
    font-weight: $fontWeightBold;
    color: $textLightBlack;

    @include pc {
        font-size: 24px;
        margin-bottom: 16px;
    }
}

.About_skills_item_title_icon {
    margin-right: 8px;
}

.About_skills_item_content {
    line-height: 1.65em;
    font-size: 16px;
    font-weight: $fontWeightNormal;

    @include pc {
        line-height: 1.7em;
        font-size: 18px;
    }
}

// image
.About_me_image_wrapper {
    margin-bottom: 28px;
}

.About_me_image {
    width: 100%;
    height: auto;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    // border-radius: 4px;

    @include pc {
        width: 400px;
    }
}

// travel
.About_travel {
    padding: 0x 12px 24px 12px;
    border-radius: 8px;

    @include pc {
        padding: 0 20px 60px 20px;
        margin-top: 80px;
    }
}

.About_travel_title {
    text-align: center;
    font-weight: $fontWeightNormal;
    letter-spacing: $LetterSpaceBase;
    font-size: 20px;
    line-height: $LetterHeightBase;
    margin-bottom: 24px;

    @include pc {
        margin-bottom: 32px;
        font-size: 24px;
    }

    .blue {
        color: $navy;
        font-weight: $fontWeightBold;

        @include pc {
            font-size: 1.2em;
        }
    }
}

.About_travel_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 600px;
    margin: auto;

    @include pc {
        width: 950px;
        max-width: 950px;
    }
}

.About_travel_list_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    align-items: center;

    @media (min-width: 690px) {
        width: 33.33%;
    }
}

.About_travel_list_img {
    width: 150px;
    margin-bottom: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 2px;

    @include pc {
        width: 280px;
    }
}

.About_travel_list_location {
    display: flex;
    align-items: center;
    margin: auto;
    font-weight: $fontWeightNormal;
    font-size: 16px;
    letter-spacing: $LetterSpaceBase;
}

.About_travel_list_location_icon {
    height: 14px;
    margin-right: 4px;
}