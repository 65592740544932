/* bring in normalize.css styles rest of app styles */
@import-normalize;

@import "./responsive.scss";

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $fontWeightNormal;
}

a {
  text-decoration: none;
  color: $textBlack;
}

ul, li {
  list-style: none;
  color: $textBlack;
}

p {
  font-weight: $fontWeightNormal;
  letter-spacing: $LetterSpaceBase;
  line-height: $LetterHeightBase;
  font-size: 16px;

  @include pc {
    font-size: 18px;
  }
}

.sp_only {
  @include pc {
    display: none;
  }
}

.pc_only {
  display: none;

  @include pc {
    display: flex;
  }
}

