@import "../../css/variables.scss";

.Page404_title {
    text-align: center;
    font-weight: $fontWeightBold;
    color: $navy;
    font-size: 4em;
    letter-spacing: $LetterSpaceCap;
    margin-top: 100px;
    margin-bottom: 8px;

    @include pc {
        font-size: 5em;
        margin-top: 120px;
    }
}

.Page404_text {
    text-align: center;
    font-size: 1.2em;
    letter-spacing: $LetterSpaceBase;
    margin-bottom: 40px;
}

.Page404_link {
    display: block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: $LetterSpaceMiddle;
    font-size: 0.95em;
    margin: auto;
    padding: 12px;
    border: 1px solid $navy;
    color: $navy;
    font-weight: $fontWeightNormal;
    width: 200px;
}
