/* google font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Zilla+Slab:wght@500;700&display=swap');

/* font */
$fontText: 'Inter', sans-serif;
$fontTitle: 'Zilla Slab', serif;
$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

/* text */
$LetterSpaceBase: 0.0125em;
$LetterSpaceMiddle: 0.015em;
$LetterSpaceCap: 0.05em;
$LetterHeightBase: 1.25em;
$LetterHeightBig: 1.8em;

/* color */
$navy: #42518D;
$blue:#3970B8;
$pink: #FBEFF1;
$offWhite: #f9f9f9;
$textWhite: #ffffff;
$textBlack: #242424;
$textLightBlack: #414141;
// $textLightBlack: rgb(79, 79, 79);
$textGray: #646464;

/* media query */
$tablet: 768px;
$pc: 1280px;

/* transition hover */
$transition_hover: all .5s ease;