@import "../../css/variables.scss";

.Work {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 10px;
    padding-top: 100px;

    @include pc {
        width: 1100px;
        margin-bottom: 0px;
        padding-top: 120px;
    }
}

.Work_title {
    text-transform: uppercase;
    color: $navy;
    letter-spacing: $LetterSpaceCap;
    font-weight: $fontWeightBold;
    font-size: 32px;
    margin-bottom: 48px;
    text-align: center;
    display: flex;
    align-items: center;

    @include pc {
        font-size: 40px;
        margin-bottom: 88px;
    }
}

.Work_title:after,
.Work_title:before {
    content: "";
    height: 1px;
    flex-grow: 1;
    background: $navy;
}

.Work_title:before {
    margin-right: 1rem;

    @include pc {
        margin-right: 1.5rem;
    }
}
 
.Work_title:after {
    margin-left: 1rem;

    @include pc {
        margin-left: 1.5rem;
    }
}

.Work_caseStudy_list {

    @include pc {
        margin-bottom: 24px;
    }
}

// case study
.Work_caseStudy_wrapper {
    margin-bottom: 120px;
    
    @include pc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 140px;
    }

    &__even {
        @include pc {
            // display: flex;
            flex-direction: row-reverse;
        }
    }
}

.Work_caseStudy_row {
    @include pc {
        width: 500px
    }
}

.Work_caseStudy_row:nth-child(2) {
    @include pc {
        margin-top: 80px;
    }
}

// .Work_caseStudy_row__even {
//     @include pc {
//         // margin-top: 80px;
//         margin-left: 0px;
//         margin-right: 48px;
//     }
// }

.Work_caseStudy_row__even {
    @include pc {
        margin-left: 0;
        margin-right: 48px;
    }
}

.Work_caseStudy_thumbnail {
    position: relative;
    transition: $transition_hover;
}

.Work_caseStudy_thumbnail_img {
    width: 100%;
    height: auto;
    margin-bottom: 28px; 
    transition: $transition_hover;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @include pc {
        width: 500px;
    }
}

.Work_caseStudy_thumbnail_mask {
    display: none;

    @include pc {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        height: 500px;
        transition: $transition_hover;
    }
}

.Work_caseStudy_thumbnail_mask:hover {
    opacity: 1;
    transition: $transition_hover;
}

.Work_caseStudy_thumbnail:hover {

    @include pc {
        transform:scale(0.98,0.98);
        transition: $transition_hover;
    } 
}    

.Work_caseStudy_thumbnail_img:hover {

    @include pc {
        transform:scale(0.98,0.98);
        transition: $transition_hover;
        // box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    } 
}

.Work_caseStudy_title_wrapper {
    margin-bottom: 16px;
    letter-spacing: $LetterSpaceMiddle;
}

// title
.Work_caseStudy_title_sub {
    font-size: 16px;
    display: block;
    margin-bottom: 4px;
    color: $textGray;
    letter-spacing: $LetterSpaceMiddle;
    font-weight: $fontWeightMedium;

    @include pc {
        font-size: 20px;
        margin-bottom: 8px;
    }
}

.Work_caseStudy_title {
    font-size: 28px;
    font-weight: $fontWeightMedium;
    letter-spacing: $LetterSpaceBase;
    line-height: $LetterHeightBase;

    @include pc {
        font-size: 36px;
    }
}

// type
.Work_caseStudy_type {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.Work_caseStudy_type_item {
    padding: 8px 12px;
    margin-right: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    letter-spacing: $LetterSpaceCap;
    text-transform: uppercase;
    border-radius: 3px;
    color: $blue;
    background-color: #e2eef6;
    font-weight: $fontWeightMedium;

    &__chataid {
        color: #b18db3;
        background-color: #F8EBF1;
    }

    &__pPlane {
        color: #e06969;
        background-color: #f8d6d6;
    }

    &__monaCo {
        color: #e08b29;
        background-color: #fdeedc;
    }

    &__veganMenu {
        color: #91A88E;
        background-color: #E2EFE0;
    }

    &__behance {
        color: #4784fd;
        background-color: #e7edfa;
    }
}

.Work_caseStudy_text {
    line-height: $LetterHeightBig;
    letter-spacing: $LetterSpaceBase;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: $fontWeightLight;
    color: $textLightBlack;

    @include pc {
        font-size: 18px;
    }
}

// link
.Work_caseStudy_link {
    text-transform: uppercase;
    letter-spacing: $LetterSpaceCap;
    display: flex;
    align-items: center;
    transition: all .3s linear;

    @include pc {
        width: 150px;
    }
}

.Work_caseStudy_link:hover {

    @include pc {
        margin-left: 24px;
        transition: all .3s linear;
    }
}

.Work_caseStudy_link_text {
    font-size: 16px;
    transition: all .3s linear;
    font-weight: $fontWeightMedium;
}

.Work_caseStudy_link_text:hover {
    @include pc {
        color: $blue;
        transition: all .3s linear;
    }
}

.Work_caseStudy_link_text__chataid:hover {
    @include pc {
        color: #C8A3B5;
    }
}

.Work_caseStudy_link_text__monaCo:hover {
    @include pc {
        color: #e08b29;
    }
}

.Work_caseStudy_link_text__veganMenu:hover {
    @include pc {
        color: #91A88E;
    }
}

.Work_caseStudy_link_text__behance:hover {
    @include pc {
        color: #4784fd;
    }
}

.Work_caseStudy_link_icon {
    margin-left: 4px;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: moveLeft;
    animation-duration: 0.7s;
}

// coming soon
.coming_soon {
    text-transform: uppercase;
    letter-spacing: $LetterSpaceCap;
    color: #BDBDBD;
}

.coming_soon:hover {
    color: #BDBDBD;
}

// Daily UI & Behance
.Work_caseStudy_list_row {

    @include pc {
        display: flex;
        justify-content: space-between;
    }
}

.Work_caseStudy__small {

    @include pc {
        width: 500px;
    }
}

.Work_caseStudy_wrapper__small {
    
    @include pc {
        display: flex;
        flex-direction: column;
    }
}

@keyframes moveLeft{
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(16px, 0);
    }
}

// others
.Work_others {
    margin-bottom: 80px;

    @include pc {
        text-align: center;
        margin-bottom: 120px;
    }
}

.Work_others_side {
    width: 22px;

    &--left {
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: .7s;
        animation: MoveRight;
    }

    &--right {
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: .7s;
        animation: MoveLeft;
    }
}

@keyframes MoveLeft {
    0%{
        opacity: 0;
      }
      100%{
        opacity: 1;
      }
}

@keyframes MoveRight {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(50px, 0);
    }
}

.Work_others_link {
    font-size: 1.1em;
    letter-spacing: $LetterSpaceBase;
    position: relative;
    text-align: center;
    transition: $transition_hover;
    margin-bottom: 40px;
    display: block;

    @include pc {
        width: 400px;
        margin: auto;
        margin-bottom: 40px;
        font-size: 1.4em;
    }
}

.Work_others_link:hover {
    @include pc {
        transition: $transition_hover;
        transform:scale(1.1,1.1);
    }
}

@keyframes MoveBig {
    0% {
        transform:scale(1,1);
    }
    100% {
        transform:scale(1.04,1.04);
    }
}

@keyframes MoveSmall {
    0% {
        transform:scale(1,1);
    }
    100% {
        transform:scale(0.96,0.96);
    }
}

// Behance
.Work_others_list {
    display: flex;
    flex-direction: column;

    @include pc {
        flex-direction: row;
        justify-content: space-between;
        width: 1100px;
        margin: auto;
    }
}

.Work_others_list_item {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 24px;
    transition: $transition_hover;

    @include pc {
        width: 320px;
        margin-bottom: 0;
    }
}

.Work_others_list_item:hover {
    @include pc {
        transform:scale(1.15,1.15);
        transition: $transition_hover;
    } 
}


.Work_others_link_ico {
    // display: block;
    // margin-top: 8px;
    // margin-left: 3px;
    padding-left: 6px;

    @include pc {
        // display: inline;
        margin-left: 2px;
    }
}
